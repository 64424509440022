<template>
	<div
		class="modal fade"
		id="assignBusModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="assignBusLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="driver"
			>
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="assignBusLabel">
						Assign Vehicle to Driver
					</h3>
					<button
						type="button"
						class="close"
						@click="onClose"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form @submit.prevent="assignBus">
						<div class="row">
							<div class="col-12">
								<label class="form-label">Select Vehicle</label>
								<v-select
									v-model="form.vehicle"
									class="form-group"
									:options="computedVehicles"
									label="name"
									:filterBy="vehicleFilter"
									required
									placeholder="Select a vehicle to assign"
									>
									<template v-slot:option="vehicle">
										{{ vehicle.seats }} Seater - {{ vehicle.brand }}
										{{ vehicle.name }} {{ vehicle.registration_number }}
									</template>
									<template v-slot:selected-option="vehicle">
										{{ vehicle.seats }} Seater - {{ vehicle.brand }}
										{{ vehicle.name }} {{ vehicle.registration_number }}
									</template>
								</v-select>
							</div>
						</div>
						<template v-if="form.vehicle">
							<h5 class="mt-2">Vehicle Information</h5>
							<div class="list-group list-group-flush my-4">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Brand</span>
										</div>
										<div class="col-auto">
											<span class="">{{ form.vehicle.brand }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Name</span>
										</div>
										<div class="col-auto">
											{{ form.vehicle.name }}
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>Plate Number</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{
												form.vehicle.registration_number
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Capacity</span>
										</div>
										<div class="col-auto">
											<span class="">{{ form.vehicle.seats }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Type</span>
										</div>
										<div class="col-auto">
											<span class="">{{ form.vehicle.type }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Amenities</span>
										</div>
										<div class="col-auto">
											<span class="">{{ form.vehicle.amenities }}</span>
										</div>
									</div>
								</div>
							</div>
						</template>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="onClose"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="assignBus"
						:disabled="processing || !formValid"
						>
						{{ processing ? 'Processing...' : 'Assign Vehicle' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#assignBusModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'
import { filterVehicleBy } from '@/utils/helpers'

export default {
  props: {
    show: Boolean,
    driver: Object,
    vehicles: Array
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        vehicle: null
      },
      processing: false,
      errorMessage: '',
      localVehicles: []
    }
  },
  created () {
      this.fetchVehicles()
  },
  computed: {
    formValid () {
      return this.form.vehicle
    },
    computedVehicles () {
      return this.localVehicles || this.vehicles
    }
  },
  methods: {
    fetchVehicles () {
      this.axios.get(`/v1/vehicles?limit=${500}`).then((res) => {
        this.localVehicles = res.data.data

        if (this.driver.vehicle_id) {
          this.form.vehicle = this.computedVehicles.find(
            (vehicle) => vehicle.id === this.driver.vehicle_id
          )
        }
      })
    },
    assignBus () {
      if (!this.formValid) {
        return
      }
// this.$route.params.driverId
      this.processing = true

      this.errorMessage = ''

      this.axios
        .patch(`/v1/drivers/${this.driver.id}`, {
          vehicle_id: this.form.vehicle.id
        })
        .then(() => {
          this.close()

          this.$emit('vehicle-assigned', this.form.vehicle.id)

          this.$swal({
            icon: 'success',
            title: 'Vehicle Assigned',
            text: 'Vehicle has been assigned successfully',
            showCloseButton: true
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occurred',
            text: 'An error occurred. please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => {
          this.processing = false
          this.form.vehicle = null
        })
    },
    onClose () {
      this.errorMessage = ''
      this.close()
    },
    vehicleFilter (option, label, search) {
      return filterVehicleBy(option, label, search)
    }
  }
}
</script>
