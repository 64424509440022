<template>
	<div>
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div class="bg-white border-bottom" v-else>
			<div class="header col mb-0">
				<div class="container-fluid">
					<div class="header-body py-0 mt-4 border-0">
						<div class="row align-items-end">
							<div class="col">
								<h6 class="header-pretitle">Marshal Management</h6>
								<h1 class="header-title">
									{{ marshal ? `${marshal.fname} ${marshal.lname}` : '' }}
								</h1>
							</div>
							<div class="col-auto">
								<div class="dropdown">
									<button
										class="btn btn-primary dropdown-toggle"
										type="button"
										id="dropdownMenuButton"
										data-toggle="dropdown"
										aria-expanded="false"
										>
										Actions
									</button>
									<div class="dropdown-menu col mr-3" aria-labelledby="dropdownMenuButton">

										<a
											class="dropdown-item btn btn-primary"
											href="#"
											@click.prevent="showChangePassword = true"
											>Change Password</a
											>
										<a
											class="dropdown-item btn btn-primary"
											href="#"
											@click.prevent="showBusMarshalRoute = true">
											Assign Route
										</a>
										<a class="dropdown-item" :class="isMarshalActive ? 'text-danger' : 'text-success'" href="#"
											@click.prevent="suspendOrUnsuspendMarshal">
											{{ isMarshalActive ? 'Suspend' : 'Unsuspend' }} Marshal</a>
										<a
											class="dropdown-item text-danger"
											href="#"
											@click.prevent="deleteMarshal"
											>Remove Route</a
											>
										<div class="col-8">
											<div v-for="(route,index) in busMarshalRoutes" :key="index" class="bus-captain-list">
												<div class="bus-captain-list__item">
													<span @click="viewItinerary(route)" class="route-description" :title="`${route.source} to ${route.destination}`">
														<span class="code text-success">{{ route.route_code }}</span>
														<span class="route-label">{{route.source}} to {{route.destination}}</span>
													</span>
													<i title="Click to remove user as bus captain" class="fe fe-x text-danger cursor-pointer ml-2 font-weight-bold" @click="removeAsBusCaptain(route)"></i>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container-fluid">
				<div class="row pt-3 pb-1">
					<div class="col justify-self-center align-self-center flex-column">
						<ul class="nav nav-pills">
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowMarshal',
										props: { marshalId: $route.params.marshalId },
									}"
									class="nav-link"
									active-class="active"
									>Marshals Information</router-link
									>
							</li>
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowCheckInCheckOut',
										props: { marshalId: $route.params.marshalId },
									}"
									class="nav-link"
									active-class="active"
									>Check-In-Check-Out</router-link
									>
							</li>
							<li class="nav-item">
								<router-link
									:to="{
										name: 'ShowBookings',
										props: { marshalId: $route.params.marshalId },
									}"
									class="nav-link"
									active-class="active"
									>Bookings</router-link
									>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="pt-4">
			<div class="container-fluid">
				<template>
					<b-breadcrumb class="p-2 m-1" :items="items"></b-breadcrumb>
				</template>
				<router-view></router-view>
			</div>
		</div>
		<template v-if="marshal">
			<add-route-to-entity-modal
				:show="showAddMarshalRoute"
				:entity="marshal"
				@close="showAddMarshalRoute = false"
				@route-added="processRouteAdded"
				></add-route-to-entity-modal>
			<change-user-password-modal
				:show="showChangePassword"
				:user="marshal"
				entity-type="bus-marshals"
				@close="showChangePassword = false"
				></change-user-password-modal>
			<assign-bus-to-driver-modal
				:show="showAssignBusToMarshal"
				:marshal="marshal"
				:vehicles="vehicles"
				@close="showAssignBusToMarshal = false"
				@vehicle-assigned="processVehicleAssigned"
				></assign-bus-to-driver-modal>
			<bus-marshal-route
				:show="showBusMarshalRoute"
				v-if="marshal"
				:user-id="marshal.id"
				@close="showBusMarshalRoute = false"
				@changes-saved="refreshMarshalData" />
		</template>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import AddRouteToEntityModal from '@/components/modals/AddRouteToEntityModal.vue'
import ChangeUserPasswordModal from '@/components/modals/ChangeUserPasswordModal.vue'
import AssignBusToDriverModal from '@/components/modals/AssignBusToDriverModal.vue'
import busMarshalService from '@/api/bus_marshal'
import BusMarshalRoute from '@/views/Marshals/components/modals/BusMarshalRoute'

export default {
  components: {
    AddRouteToEntityModal,
    ChangeUserPasswordModal,
    AssignBusToDriverModal,
    BusMarshalRoute
  },
  props: {
    marshalId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      items: [
        {
          text: 'Marshal Management',
          href: '#'
        },
        {
          text: 'Marshal List',
          href: '#'
        },
        {
          text: ''
        }
      ],
      processingExecution: false,
      loading: true,
      errorLoading: false,
      currentCreditSystem: null,
      needCreditSystem: false,
      error: null,
      trips: [],
      vehicles: [],
      showAddMarshalRoute: false,
      showChangePassword: false,
      showChangeProfilePicture: false,
      showAssignBusToMarshal: false,
      showAssignRouteToMarshal: false,
      showEditMarshalBus: false,
      showStartOrStopTrip: false,
      showBusMarshalRoute: false,
      currentMarshal: null,
      marshal: '',
      busMarshalRoutes: []
    }
  },
  // created() {
  //   this.init()
  // },
  computed: {
    currentUser () {
      return this.$store.getters.currentuser
    },
    isMarshalActive () {
      if (!this.marshal) {
        return false
      }

      return !!parseInt(this.marshal.active)
    },
    isMarshalBlocked () {
      return !!this.marshal.is_blocked
    },
    marshalDevice () {
      return this.marshal &&
        Object.prototype.hasOwnProperty.call(this.marshal, 'device')
        ? this.marshal.device?.device_brand
        : 'Not Available'
    }
  },
  mounted () {
    this.fetchMarshal(this.marshalId)
  },
  methods: {
    async fetchMarshal (marshalId) {
      try {
        this.loading = true
        const result = await this.axios.get(`/v1/bus-marshals/${marshalId}`)
        this.marshal = result.data
        this.items[2] = this.marshal.fname + ' ' + this.marshal.lname
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    fetchVehicles () {
      return this.axios.get('/v1/vehicles').then((res) => {
        this.vehicles = res.data
      })
    },
    async suspendOrUnsuspendMarshal () {
      const isSuspendAction = this.isMarshalActive

      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${this.isMarshalActive ? 'suspend' : 'unsuspend'
          } this Marshal?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/bus-marshals/${this.marshalId}`, {
              active: this.isMarshalActive ? 0 : 1
            })
            .then((res) => {
              this.marshal = res.data

              return res.data
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: `Marshal has been ${isSuspendAction ? 'suspended' : 'unsuspended'
              } successfully`,
            showCloseButton: true
          })
        }
      })
    },
    async deleteMarshal () {
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to delete this marshal?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .delete(`/v1/bus-marshals/${this.marshalId}`)
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Marshal has been deleted successfully',
            showCloseButton: true
          })
        }
      })
    },
    //
    async unblockMarshal () {
      const isBlockedAction = this.isMarshalBlocked
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${
          this.isMarshalBlocked ? 'unblock' : 'block'
        } this marshal?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/bus-marshals/${this.marshalId}/blocked`, {
              isBlocked: !this.isMarshalBlocked
            })
            .then((res) => {
              this.marshal = res.data
              return res.data
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'
              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: `marshal has been ${
              isBlockedAction ? 'unblocked' : 'blocked'
            } successfully`,
            showCloseButton: true
          })
        }
      })
    },
    removeAsBusCaptain (busMarshalRoute) {
      busMarshalService
        .removeAsBusCaptain(busMarshalRoute.id, this.$swal,
          `${this.user.fname} ${this.user.lname}`,
          {
            successFxn: () => this.refreshUserData()
          }, `Are you sure you want to remove user from being a bus captain from this route ?
          ${busMarshalRoute.route_code} - ${busMarshalRoute.departure_time}`)
    },
    refreshUserData () {
      this.fetchUser()
    },
    viewItinerary (busMarshalRoute) {
      this.$router.push({
        name: 'ShowRouteItinerary',
        params: {
          routeId: busMarshalRoute.route_id,
          itineraryId: busMarshalRoute.route_itinerary_id
        }
      })
    },
    processRouteAdded () {
      this.fetchMarshalTrips()
    },
    processVehicleAssigned (vehicleId) {
      this.marshal.vehicle_id = vehicleId
    },
    processVehicleUpdated (vehicle) {
      this.marshal.vehicle = { ...vehicle }
    }
  }
}

</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}

.VueTables__search-field > label {
  display: none;
}

.VueTables__search-field {
  margin-top: 20px;
}
</style>
