import Swal from 'sweetalert2'
import { axiosInstance } from '@/plugins/axios'

export class BusMarshalService {
  /**
  * @param {AxiosInstance} axios
  * */
  constructor (axios) {
    this.axios = axios
  }

  removeAsBusMarshal (id, swal, marshalId, { successFxn, errorFxn }, promptMessage = null) {
    swal({
      icon: 'question',
      title: 'Please Confirm',
      text: promptMessage || `Are you sure you want to remove ${marshalId} as bus marshal?`,
      showConfirmButton: true,
      showCancelButton: true,
      preConfirm: () => {
        return this.axios
          .delete(`/v1/bus-marshals/${id}`)
          .catch((error) => {
            const msg =
              error.response && error.response.data
                ? error.response.data.message
                : 'An error occurred, please try again.'
            swal().showValidationMessage(msg)
            errorFxn.call(this, error)
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        successFxn.call(this)
        swal({
          icon: 'success',
          title: 'Success',
          text: 'Action successful!',
          showCloseButton: true
        })
      }
    })
  }

  /**
   * @param {Number,String} payload.user_id,
   * @param {Number,String} payload.route_itinerary_id,
  * */
  addAsBusMarshal (payload) {
    return this.axios.post('/v1/bus-marshals/', payload)
  }

  editAsBusMarshal (payload, id) {
    return this.axios.patch(`/v1/bus-marshals/${id}`, payload)
  }

  fetchAllBusMarshals (page, size) {
    const params = {
      page: page,
      limit: size,
      related: 'user,route,itinerary'
    }
    return this.axios.get('/v1/bus-marshals', { params })
  }
}

const busMarshalService = new BusMarshalService(axiosInstance)

export default busMarshalService
